<div class="cdb-block">
  <div>
    <label class="uom-label">{{'Case' | translate }}</label>
    <mat-form-field class="cdb-form-field-number">
      <input matInput
             class="number"
             type="number"
             [(ngModel)]="uomModel.caseFrom"
             [ngModelOptions]="{ updateOn: 'blur' }"
             (ngModelChange)="emitUnitChanged()">
    </mat-form-field>
    <span class="cdb-form-field-to">{{'To' | translate }}</span>
    <mat-form-field class="cdb-form-field-number">
      <input matInput
             class="number"
             type="number"
             [(ngModel)]="uomModel.caseTo"
             [ngModelOptions]="{ updateOn: 'blur' }"
             (ngModelChange)="emitUnitChanged()">
    </mat-form-field>
  </div>

  <div>
    <label class="uom-label">{{'Pack' | translate }}</label>
    <mat-form-field class="cdb-form-field-number">
      <input matInput
             class="number"
             type="number"
             [(ngModel)]="uomModel.packFrom"
             [ngModelOptions]="{ updateOn: 'blur' }"
             (ngModelChange)="emitUnitChanged()">
    </mat-form-field>
    <span class="cdb-form-field-to">{{'To' | translate }}</span>
    <mat-form-field class="cdb-form-field-number">
      <input matInput
             class="number"
             type="number"
             [(ngModel)]="uomModel.packTo"
             [ngModelOptions]="{ updateOn: 'blur' }"
             (ngModelChange)="emitUnitChanged()">
    </mat-form-field>
  </div>
  <div>
    <label class="uom-label">{{'Unit' | translate }}</label>
    <mat-form-field class="cdb-form-field-number">
      <input matInput
             class="number"
             type="number"
             [(ngModel)]="uomModel.unitFrom"
             [ngModelOptions]="{ updateOn: 'blur' }"
             (ngModelChange)="emitUnitChanged()"
      />
    </mat-form-field>
    <mat-form-field class="cdb-form-field-number uom-form-field-measure-1"
    >
      <mat-label *ngIf="!uomModel.unitFromMeasure">{{'Measure' | translate }}</mat-label>
      <mat-select [(ngModel)]="uomModel.unitFromMeasure"
                  (selectionChange)="emitUnitChanged()"
                  [compareWith]="objectComparisonFunction">
        <mat-option [value]="">{{'N/A' | translate }}</mat-option>
        <mat-option *ngFor="let measure of measures$ | async" [value]="measure">{{ measure.name }}</mat-option>
      </mat-select>
    </mat-form-field>
    <span class="cdb-form-field-to">{{'To' | translate }}</span>
    <mat-form-field class="cdb-form-field-number">
      <input matInput
             class="number"
             type="number"
             [(ngModel)]="uomModel.unitTo"
             [ngModelOptions]="{ updateOn: 'blur' }"
             (ngModelChange)="emitUnitChanged()"
      />
    </mat-form-field>
    <mat-form-field class="cdb-form-field-number uom-form-field-measure-2">
      <mat-label *ngIf="!uomModel.unitToMeasure">{{'Measure' | translate }}</mat-label>
      <mat-select [(ngModel)]="uomModel.unitToMeasure"
                  (selectionChange)="emitUnitChanged()"
                  [compareWith]="objectComparisonFunction">
        <mat-option [value]="">{{'N/A' | translate }}</mat-option>
        <mat-option *ngFor="let measure of measures$ | async" [value]="measure">{{ measure.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <p class="mat-warn" *ngIf="showFactoredMeasuresWarning">{{'You cannot search with measures that cannot be factored please choose different measures' | translate}}</p>
</div>
