import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SavedSearchDto} from '../dtos';
import {Config} from '../models';
import {RootStore} from '../store/root.store';

@Injectable()
export class SavedSearchService {
  config: Config;

  constructor(private readonly rootStore: RootStore,
              private readonly httpClient: HttpClient) {
    this.rootStore.getConfig.subscribe((c) => this.config = c);
  }

  get(basketItemId: string): Observable<SavedSearchDto> {
    return this.httpClient.get<SavedSearchDto>(`${this.config.endpoints.savedSearchEndpoint}?procuremntBasketItemId=${basketItemId}`);
  }
}
