import {TranslateLoader} from '@ngx-translate/core';
import {Observable, of} from 'rxjs';
import de from '../assets/i18n/de.json';
import en from '../assets/i18n/en.json';
import es_ES from '../assets/i18n/es-es.json';
import fr_BE from '../assets/i18n/fr-be.json';
import fr from '../assets/i18n/fr.json';
import it from '../assets/i18n/it.json';
import nl_BE from '../assets/i18n/nl-be.json';
import nl from '../assets/i18n/nl.json';

const availableLanguages = {
  de,
  en,
  es_ES,
  fr,
  fr_BE,
  it,
  nl,
  nl_BE
};

export class CustomTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return of(availableLanguages[this.normaliseLanguageCode(lang)]);
  }

  private normaliseLanguageCode(language: string): string {
    return language.replace('-', '_');
  }
}
