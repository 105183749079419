import {AzureAttributeModel} from './azure-attribute.model';

export class AttributeModel {
  /**
   * Creates a new AttributeModel from a value from Azure Search.
   * @param azureAttributeModel Attribute model from Azure Search.
   */
  constructor(criteriaId: number, attributeCriteriaId: number, azureAttributeModel?: AzureAttributeModel) {
    this.attributeCriteriaId = attributeCriteriaId;
    this.criteriaId = criteriaId;
    if (azureAttributeModel) {
      this.productAttributeDescription = azureAttributeModel.productAttributeName;
      this.attributeGroupDescription = azureAttributeModel.attributeGroupName;
      this.id = azureAttributeModel.id;
      this.isInclude = true; // Default to true as it's a new attribute
    }
  }

  attributeGroupId: number;
  attributeGroupDescription: string;
  id: number;
  isInclude: boolean;
  productAttributeDescription: string;
  criteriaId: number;
  attributeCriteriaId: number;
}
