import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {filter, take} from 'rxjs/operators';
import {SavedSearchTypeEnum} from '../../enums';
import {SearchHeaderModel} from '../../models';
import {SkuStore} from '../../store/sku.store';

@Component({
  selector: 'cdb-sku-save-bar',
  templateUrl: './sku-save-bar.component.html',
  styleUrls: ['./sku-save-bar.component.css']
})
export class SkuSaveBarComponent implements OnInit {
  @Input() disableSelect: boolean;
  @Input() searchHeader: SearchHeaderModel;
  @Input() skusSaving: boolean;
  @Input() disableSave: boolean;
  @Output() saveSku = new EventEmitter<number>();
  savedSearchType: SavedSearchTypeEnum;
  savedSearchTypeEnum: typeof SavedSearchTypeEnum = SavedSearchTypeEnum;

  constructor(private readonly skuStore: SkuStore) {
    this.skuStore.select((state) => state.saveOption)
      .pipe(filter(s => s !== undefined))
      .pipe(take(1))
      .subscribe((savedSearchType) => {
        this.savedSearchType = savedSearchType;
      });
  }

  ngOnInit(): void {
  }

  updateSkuSaveOption(): void {
    this.skuStore.updateSkuSaveOption(this.savedSearchType);
  }

  emitSaveSku(): void {
    this.saveSku.emit();
  }

  disableSaveButton(): boolean {
    return this.disableSave || this.savedSearchType !== this.savedSearchTypeEnum.advancedSearchUseRecommended;
  }
}
