import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ComponentStore} from '@ngrx/component-store';
import {
  TranslateCompiler, TranslateLoader,
  TranslateModule
} from '@ngx-translate/core';
import {ToastrModule} from 'ngx-toastr';
import {TranslateMessageFormatCompiler} from 'ngx-translate-messageformat-compiler';
import {AdvancedSearchContainerComponent} from './components/advanced-search-container/advanced-search-container.component';
import {AdvancedSearchHeaderContainerComponent} from './components/advanced-search-header-container/advanced-search-header-container.component';
import {AdvancedSearchHeaderComponent} from './components/advanced-search-header/advanced-search-header.component';
import {AttributeCriteriaContainerComponent} from './components/attribute-criteria-container/attribute-criteria-container.component';
import {AttributeCriteriaComponent} from './components/attribute-criteria/attribute-criteria.component';
import {AttributeTagsComponent} from './components/attribute-tag/attribute-tags.component';
import {CriteriaBuilderContainerComponent} from './components/criteria-builder-container/criteria-builder-container.component';
import {CriteriaBuilderComponent} from './components/criteria-builder/criteria-builder.component';
import {SkuResultsContainerComponent} from './components/sku-results-container/sku-results-container.component';
import {SkuResultsComponent} from './components/sku-results/sku-results.component';
import {SkuSaveBarComponent} from './components/sku-save-bar/sku-save-bar.component';
import {UomCriteriaContainerComponent} from './components/uom-criteria-container/uom-criteria-container.component';
import {UomCriteriaComponent} from './components/uom-criteria/uom-criteria.component';
import {SaveServiceFactory} from './factories';
import {AuthenticationInterceptor, HttpErrorInterceptor, LanguageInterceptor} from './interceptors';
import {CustomTranslateLoader} from './loaders/CustomTranslate.loader';
import {
  AttributeService,
  CriteriaTransformerService, FactoredMeasuresService,
  SavedSearchService,
  SkuSearchService
} from './services';
import {MeasureService} from './services/measure.service';
import {CriteriaBuilderStore} from './store/criteria-builder.store';
import {MeasureStore} from './store/measure.store';
import {RootStore} from './store/root.store';
import {SearchHeaderStore} from './store/search-header.store';
import {SkuStore} from './store/sku.store';

@NgModule({
  declarations: [
    AttributeCriteriaComponent,
    AdvancedSearchContainerComponent,
    CriteriaBuilderComponent,
    CriteriaBuilderContainerComponent,
    UomCriteriaComponent,
    UomCriteriaContainerComponent,
    AttributeCriteriaContainerComponent,
    AttributeTagsComponent,
    SkuResultsContainerComponent,
    SkuResultsComponent,
    SkuSaveBarComponent,
    AdvancedSearchHeaderContainerComponent,
    AdvancedSearchHeaderComponent
  ],
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    CommonModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatInputModule,
    MatChipsModule,
    FlexLayoutModule,
    MatCheckboxModule,
    MatTableModule,
    MatSelectModule,
    MatButtonModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {provide: TranslateLoader, useClass: CustomTranslateLoader},
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      }
    }),
    MatSortModule,
    MatTooltipModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      closeButton: true,
      timeOut: 0
    })
  ],
  providers: [
    AttributeService,
    ComponentStore,
    CriteriaBuilderStore,
    FactoredMeasuresService,
    RootStore,
    SaveServiceFactory,
    SavedSearchService,
    SearchHeaderStore,
    SkuStore,
    SkuSearchService,
    CriteriaTransformerService,
    MeasureStore,
    MeasureService,
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true}
  ],
  exports: [AdvancedSearchContainerComponent]
})
export class AdvancedSearchModule {
}
