<div class="criteria-block"
     fxLayout="row"
     fxLayoutAlign="start center">
  <div>
    <mat-radio-group [(ngModel)]="attributeCriteria.operator"
                     (ngModelChange)="emitRadioChanged(attributeCriteria.operator, attributeCriteria.id)">
      <mat-radio-button [value]="operatorEnum.All">{{'All' | translate }}</mat-radio-button>
      <mat-radio-button [value]="operatorEnum.Any">{{'Any' | translate }}</mat-radio-button>
    </mat-radio-group>
  </div>
  <div fxFlex>
    <cdb-attribute-tags [attributes]="attributeCriteria.attributes"
                        [attributeOperator]="attributeCriteria.operator"
                        (attributeIsIncludeChanged)="emitAttributeIsIncludeChanged($event)"
                        (attributeSelected)="emitAddAttribute($event)"
                        (attributeSearchTermChanged)="emitAttributeSearchTermChanged($event, attributeCriteria.id)"
                        (clearAttributeSearch)="emitClearAttributeSearch()"
                        (removeAttribute)="emitRemoveAttribute($event)"></cdb-attribute-tags>
  </div>
  <div>
    <a class="fa fa-close"
       *ngIf="!disableDelete"
       (click)="emitRemoveAttributeCriteria(attributeCriteria)"></a>
  </div>
</div>
