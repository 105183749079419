import {BasketTypeEnum, SearchRankTypeEnum} from '../enums';
import {SearchHeaderModel, SkuModel} from '../models';
import {SearchCriteriaDto} from './search-criteria.dto';
import {SearchDto} from './search.dto';

export class SaveSkuDto {
  constructor(sku: SkuModel,
              searchCriteriaDto: SearchCriteriaDto,
              searchHeader: SearchHeaderModel) {
    this.searchDto.searchCriteria = searchCriteriaDto;
    this.searchDto.name = searchHeader.name;
    this.isBestPrice = sku.isBestPrice;
    this.itemPrice = sku.priceItemPrice;
    this.rankPrice =
      searchHeader.priceRank === SearchRankTypeEnum.bestPrice ?
        sku.priceFactoredUpliftedPriceToNormalisedMeasure :
        sku.pricePortionPrice;
  }

  searchDto: SearchDto = new SearchDto();
  clientId: number;
  skuId: number;
  organisationId: number;
  procurementBasketId: string;
  procurementBasketTypeEnum: BasketTypeEnum;
  clientSupplierAccountDto: { id: number };
  rankPrice: number;
  itemPrice: number;
  isBestPrice: boolean;
}

export class SaveSkuSegmentDto extends SaveSkuDto {
  segmentId: number;
}
