import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UomCriteriaModel} from '../../models';
import {CriteriaBuilderStore} from '../../store/criteria-builder.store';
import {MeasureStore} from '../../store/measure.store';

@Component({
  selector: 'cdb-uom-criteria-container',
  templateUrl: './uom-criteria-container.component.html',
  styleUrls: ['./uom-criteria-container.component.css']
})
export class UomCriteriaContainerComponent {
  @Input() uomModel: UomCriteriaModel;
  @Output() doSearch = new EventEmitter<any>();
  factoredMeasures$ = this.measuresStore.select((state) => state.factoredMeasures);

  constructor(private readonly criteriaBuilderStore: CriteriaBuilderStore,
              private readonly measuresStore: MeasureStore) {
  }

  unitChanged($event: UomCriteriaModel): void {
    this.criteriaBuilderStore.updateUomCriteria($event);
    this.doSearch.emit();
  }
}
