import {OperatorEnum} from '../enums';
import {AttributeModel} from './attribute.model';

export class AttributeCriteriaModel {
  constructor(criteriaId: number, operator?: OperatorEnum, id?: number) {
    this.criteriaId = criteriaId;
    this.id = id;
    this.operator = operator;
  }

  id: number;
  operator: OperatorEnum;
  criteriaId: number;
  attributes: AttributeModel[] = [];
}
