import {SaveSkuDto, SearchDto} from './';

export class ReplaceSkuDto {
  procurementItemDto: SaveSkuDto;
  procurementBasketId: string;
  sortOrder: number;
}

export class ReplaceSkuSegmentDto extends ReplaceSkuDto {
  procurementSegmentItemId: number;
  procurementSegmentId: number;
}

export class ReplaceBasketSkuDto extends ReplaceSkuDto {
  // Id of the current BasketItem
  id: string;
  organisationId: number;
  skuId: number;
  clientId: number;
  searchDto: SearchDto;
}
