import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {SearchRankTypeEnum} from '../../enums';
import {SearchHeaderModel} from '../../models';

@Component({
  selector: 'cdb-advanced-search-header',
  templateUrl: './advanced-search-header.component.html',
  styleUrls: ['./advanced-search-header.component.css']
})
export class AdvancedSearchHeaderComponent implements OnInit, OnChanges {
  @Input() searchHeader: SearchHeaderModel;
  @Input() returnUrl: string;
  @Output() doSearch = new EventEmitter<any>();
  @Output() headerChanged = new EventEmitter<SearchHeaderModel>();
  priceRankEnum: typeof SearchRankTypeEnum = SearchRankTypeEnum;
  headerFormGroup = new FormGroup({
    isUsedInRecipe: new FormControl(false),
    isUsedInListOrGuide: new FormControl(false),
    isValVol: new FormControl(false),
    isFavourite: new FormControl(false),
    name: new FormControl(''),
    priceRank: new FormControl(null)
  });

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    Object.keys(changes.searchHeader.currentValue).every((key) => {
      if (changes.searchHeader.currentValue[key] !== this.headerFormGroup.get(key).value) {
        this.headerFormGroup.patchValue({
          isUsedInRecipe: this.searchHeader.isUsedInRecipe,
          isUsedInListOrGuide: this.searchHeader.isUsedInListOrGuide,
          isValVol: this.searchHeader.isValVol,
          isFavourite: this.searchHeader.isFavourite,
          name: this.searchHeader.name,
          priceRank: this.searchHeader.priceRank
        });
        return false;
      }
      return true;
    });
  }

  ngOnInit(): void {
    this.headerFormGroup.valueChanges.subscribe((changes) => {
      this.headerChanged.emit(changes);
      if (this.searchHeader.name === changes.name) {
        this.doSearch.emit();
      }
    });
  }
}
