export class Config {
  endpoints: {
    returnUrl: string;
    attributeEndpoint: string;
    saveEndpoints: { [key: number]: string };
    replaceEndpoints: { [key: number]: string };
    savedSearchEndpoint: string;
    skuSearchEndpoint: string;
    measuresEndpoint: string;
    factoredMeasuresEndpoint: string;
  };
  userContext: {
    language: string;
    organisationId: number;
    bearerToken: string;
    clientId: number;
  };
  entityContext: {
    additionalData: any;
    basketItemId: string;
    entityId: string; // String allows us to technically handle strings or number ids.
    entityType: number;
    segmentId: number;
  };
  dynamicEnums: {
    attributeGroups: {
      supplier: number
    },
    externalAttributeGroupTypeId: number
  };
}
