/* tslint:disable:variable-name */
export abstract class TranslatableAttribute {
  attributeGroupName_de: string;
  attributeGroupName_en: string;
  attributeGroupName_es_ES: string;
  attributeGroupName_fr: string;
  attributeGroupName_fr_BE: string;
  attributeGroupName_it: string;
  attributeGroupName_nl: string;
  attributeGroupName_nl_BE: string;

  attributeName_de: string;
  attributeName_en: string;
  attributeName_es_ES: string;
  attributeName_fr: string;
  attributeName_fr_BE: string;
  attributeName_it: string;
  attributeName_nl: string;
  attributeName_nl_BE: string;
}
