import {HttpBackend, HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SearchRankTypeEnum} from '../enums';
import {AdvancedSearchFromSkuModel, Config} from '../models';
import {RootStore} from '../store/root.store';
import {LanguageUtil} from '../utilities';

@Injectable()
export class AttributeService {
  private config: Config;

  constructor(private readonly httpClient: HttpClient,
              httpBackend: HttpBackend,
              private readonly rootStore: RootStore) {
    this.rootStore.getConfig
      .subscribe((config: Config) => {
        this.config = config;
      });
    this.httpClient = new HttpClient(httpBackend);
  }

  search(term: string, language: string): Observable<any> {
    return this.httpClient.get(this.buildAttributeSearchUrl(term, language));
  }

  getAttributeSearchFromStorage(): AdvancedSearchFromSkuModel {
    const json = JSON.parse(window.localStorage.getItem('advancedSearchObj'));
    json.attributeIds = json.attributeIds.split(',');

    const advancedSearchFromSkuModel = json as AdvancedSearchFromSkuModel;
    if (!(advancedSearchFromSkuModel.priceRank in SearchRankTypeEnum)) {
      throw new Error(`Rank type '${advancedSearchFromSkuModel.priceRank}' is not a valid SearchRankTypeEnum.`);
    }
    return advancedSearchFromSkuModel;
  }

  // TODO we shouldn't really be using <any>.
  getByIds(attributeIds: number[]): Observable<any> {
    return this.httpClient.get(`${this.config.endpoints.attributeEndpoint}&search=${encodeURIComponent(attributeIds.join(' '))}&searchFields=key`);
  }

  private buildAttributeSearchUrl(term: string, language: string): string {
    return `${this.config.endpoints.attributeEndpoint}&search=${term}&searchFields=${this.getSearchFields(LanguageUtil.normaliseLanguage(language))}&scoringProfile=${this.getScoringProfile(LanguageUtil.normaliseLanguage(language))}&${encodeURIComponent('$')}filter=${this.getFilter()}`;
  }

  private getSearchFields(language: string): string {
    return `${LanguageUtil.normaliseLanguage(language)}/productAttributeKeyword,${LanguageUtil.normaliseLanguage(language)}/productAttributeName,${LanguageUtil.normaliseLanguage(LanguageUtil.normaliseLanguage(language))}/productAttributeValues,${LanguageUtil.normaliseLanguage(language)}/productAttributePartial`;
  }

  private getScoringProfile(language: string): string {
    return `${LanguageUtil.normaliseLanguage(language)}_combined`;
  }

  private getFilter(): string {
    return `attributeGroupTypeId${encodeURIComponent(` eq '${this.config.dynamicEnums.externalAttributeGroupTypeId}'`)}`;
  }
}
