import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FactoredMeasureModel, UomCriteriaModel} from '../../models';
import {FactoredMeasuresService} from '../../services';
import {MeasureStore} from '../../store/measure.store';
import {cdbIsNullOrUndefined} from '../../utilities';

@Component({
  selector: 'cdb-uom-criteria',
  templateUrl: './uom-criteria.component.html',
  styleUrls: ['./uom-criteria.component.css']
})
export class UomCriteriaComponent implements OnInit {
  @Input() factoredMeasures: FactoredMeasureModel[];
  @Input() uomModel: UomCriteriaModel;
  @Output() unitChanged = new EventEmitter<UomCriteriaModel>();
  measures$ = this.measureStore.select((state) => state.measures);
  showFactoredMeasuresWarning: boolean;

  constructor(private readonly measureStore: MeasureStore,
              private readonly factoredMeasuresService: FactoredMeasuresService) {
  }

  ngOnInit(): void {
  }

  objectComparisonFunction(option, value): boolean {
    if (option && value) {
      return option.id === value.id;
    }
    return false;
  }

  emitUnitChanged(): void {
    const areMeasuresFactorable = this.areMeasuresFactorable();
    this.showFactoredMeasuresWarning = !areMeasuresFactorable;
    // if (areMeasuresFactorable) {
    this.unitChanged.emit(this.uomModel);
    // }
  }

  private areMeasuresFactorable(): boolean {
    // We don't have two measures, don't try and compare the Ids
    if (cdbIsNullOrUndefined(this.uomModel.unitFromMeasure) || cdbIsNullOrUndefined(this.uomModel.unitToMeasure)) {
      return true;
    }

    return this.factoredMeasuresService.areMeasuresFactorable([{
      measureFrom: this.uomModel.unitFromMeasure.id,
      measureTo: this.uomModel.unitToMeasure.id
    }]);
  }
}
