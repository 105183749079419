/* tslint:disable:variable-name */
export abstract class TranslatableSku {
  skuDescription_de: string;
  skuDescription_en: string;
  skuDescription_es_ES: string;
  skuDescription_fr: string;
  skuDescription_fr_BE: string;
  skuDescription_it: string;
  skuDescription_nl: string;
  skuDescription_nl_BE: string;
}
