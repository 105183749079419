import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AdvancedSearchDto} from '../dtos';
import {Config, SkuModel} from '../models';
import {RootStore} from '../store/root.store';

@Injectable()
export class SkuSearchService {
  config: Config;
  constructor(private readonly rootStore: RootStore,
              private readonly httpClient: HttpClient) {
    this.rootStore.getConfig
      .subscribe((config: Config) => {
        this.config = config;
      });
  }

  search(advancedSearchDto: AdvancedSearchDto): Observable<any> {
    return this.httpClient.post<SkuModel[]>(`${this.config.endpoints.skuSearchEndpoint}`, advancedSearchDto);
  }
}
