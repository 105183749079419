import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {filter, take} from 'rxjs/operators';
import {ReplaceBasketSkuDto, ReplaceSkuDto, SaveSkuDto} from '../dtos';
import {BasketTypeEnum} from '../enums';
import {SavedSearch, SaveService} from '../interfaces';
import {Config} from '../models';
import {CriteriaBuilderStore} from '../store/criteria-builder.store';
import {RootStore} from '../store/root.store';

@Injectable()
export class ProcurementBasketSaveService implements SaveService {
  config: Config;
  savedSearch: SavedSearch;

  constructor(private readonly httpClient: HttpClient,
              private readonly criteriaBuilderStore: CriteriaBuilderStore,
              private readonly rootStore: RootStore) {
    rootStore.state$
      .pipe(take(1))
      .pipe(filter((s) => !!s))
      .subscribe((state) => {
        this.config = state.config;
        this.savedSearch = state.savedSearch;
      });
  }

  saveSku(skuId: number, saveSkuDto: SaveSkuDto): Observable<any> {
    saveSkuDto.procurementBasketId = this.config.entityContext.entityId;
    saveSkuDto.organisationId = this.config.userContext.organisationId;
    saveSkuDto.skuId = skuId;
    saveSkuDto.clientId = this.config.userContext.clientId;
    if (this.savedSearch !== null) {
      saveSkuDto.procurementBasketTypeEnum = this.config.entityContext.entityType;
      const replaceSkuDto: ReplaceBasketSkuDto =
        {
          clientId: this.config.userContext.clientId,
          id: this.config.entityContext.basketItemId,
          organisationId: this.config.userContext.organisationId,
          skuId,
          procurementBasketId: this.config.entityContext.entityId,
          procurementItemDto: saveSkuDto,
          sortOrder: this.savedSearch.sortOrder,
          searchDto: saveSkuDto.searchDto
        };
      return this.httpClient.post(
        this.config.endpoints.replaceEndpoints[BasketTypeEnum[this.config.entityContext.entityType]],
        replaceSkuDto
      );
    } else {
      return this.httpClient.post(this.config.endpoints.saveEndpoints[BasketTypeEnum[BasketTypeEnum.ProcurementBasket]], saveSkuDto);
    }
  }
}
