<div fxLayout="column">
  <h3 class="margin-top-10">
    {{'Found {length} SKUs' | translate:{length: skus.length} }}
    <span fxFlex></span>
    <span *ngIf="searchMeasureId === null && skus.length"
          class="mat-hint mat-warn">{{ 'cannot determine a price as your search contains mixed measures' | translate }}</span>
  </h3>
  <table mat-table
         matSort
         [dataSource]="skus"
         multiTemplateDataRows
         fxFlex
         class="margin-top-0 margin-bottom-50"
  >
    <ng-container matColumnDef="SkuSupplier">
      <th mat-header-cell
          *matHeaderCellDef>{{'Supplier' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ getSupplierAttributeName(row.productAttributes) }}</td>
    </ng-container>

    <ng-container matColumnDef="SkuDescription">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header>{{'SKU Description' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <p fxLayoutAlign="start center">
          {{row.skuDescription}}
          <span class="cdb-sku-measure">({{row.caseQty ? row.caseQty : 1}} x {{row.packQty ? row.packQty : 1}} x {{row.unit ? row.unit : 1}} {{getMeasure(row.skuMeasureId)}})</span>
          <span fxFlex></span>
          <span *ngIf="row.isBestPrice"
                class="fa fa-certificate is-best-price"
                matTooltip="{{ 'This SKU has been determined as the best priced SKU for this search' | translate }}"
          ></span>
        </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="SkuCode">
      <th mat-header-cell *matHeaderCellDef>{{'SKU Code' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{row.skuCode}}</td>
    </ng-container>

    <ng-container matColumnDef="SkuAttributes">
      <th mat-header-cell *matHeaderCellDef>{{'Attributes' | translate }}</th>
      <td mat-cell *matCellDef="let row"
      >
        <div fxLayout="column"
             class="padding-top-5-i padding-bottom-5-i"
        >
          <label *ngFor="let attr of filterAttributes(row.productAttributes)"
                 class="cdb-sku-attribute"
          >
            {{attr.productAttributeDescription}} <span class="mat-hint">({{attr.attributeGroupDescription}})</span>
            <!--            {{attr.attributeName(language)}} <span class="mat-hint">({{attr.attributeGroupName(language)}})</span>-->
          </label>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="SkuPrice">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header>{{'SKU Price' | translate }}</th>
      <td mat-cell
          *matCellDef="let row"
          class="number">
        {{row.priceCurrencySymbol}}{{row.priceItemPrice.toFixed(2)}}
      </td>
    </ng-container>

    <ng-container matColumnDef="FactoredPrice">
      <th mat-header-cell
          *matHeaderCellDef>
        <span *ngIf="searchHeader.priceRank === priceRankEnum.bestPrice">{{'Factored Price {measure}' | translate: {measure: getMeasure(searchMeasureId)} }}</span>
        <span *ngIf="searchHeader.priceRank === priceRankEnum.portion">{{'Portion Price' | translate }}</span>
      </th>
      <td mat-cell
          *matCellDef="let row"
          class="number">
        <span *ngIf="searchMeasureId !== null">{{row.priceCurrencySymbol}}{{getSkuFactoredPrice(row)}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell
          *matCellDef="let row">
        <div
          fxLayout="row"
          fxLayoutAlign="center center">
          <button *ngIf="savedSearchType === savedSearchTypeEnum.advancedSearchExactSku && searchMeasureId !== null"
                  [disabled]="disableSave()"
                  fxFlexAlign="center"
                  (click)="emitSaveSku(row)">{{'Save' | translate }} <i class="fa fa-spinner fa-spin" *ngIf="skusSaving"></i></button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
<cdb-sku-save-bar *ngIf="displaySkuSaveBar()"
                  [disableSave]="disableSave()"
                  [disableSelect]="disableSaveBarSelect"
                  [searchHeader]="searchHeader"
                  [skusSaving]="skusSaving"
                  (saveSku)="emitSaveSku()"
></cdb-sku-save-bar>
