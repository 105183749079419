import {LanguageUtil} from '../utilities';

export class AzureAttributeModel {
  constructor(searchValue: any, language: string) {
    const searchValueByLanguage = searchValue[LanguageUtil.normaliseLanguage(language)];
    this.id = searchValue.id;
    this.productAttributeName = searchValueByLanguage ? searchValueByLanguage.productAttributeName : searchValue.en.productAttributeName;
    this.attributeGroupName = searchValueByLanguage ? searchValueByLanguage.attributeGroupName : searchValue.en.attributeGroupName;
  }
  id: number;
  productAttributeName: string;
  attributeGroupName: string;
}
