import {Injectable} from '@angular/core';
import {ComponentStore} from '@ngrx/component-store';
import {Observable} from 'rxjs';
import {filter, map,  switchMap, take} from 'rxjs/operators';
import {FactoredMeasureModel, MeasureModel} from '../models';
import {MeasureService} from '../services/measure.service';

export interface MeasureState {
  measures: MeasureModel[];
  factoredMeasures: FactoredMeasureModel[];
  allMeasuresAreFactorable: boolean;
}

export const initialState: MeasureState = {
  measures: [],
  factoredMeasures: [],
  allMeasuresAreFactorable: true
};

@Injectable()
export class MeasureStore extends ComponentStore<MeasureState> {

  constructor(private measureService: MeasureService) {
    super(initialState);
  }

  readonly search = this.effect((input$: Observable<void>) => {
    return input$.pipe(
      switchMap(() => this.measureService.search().pipe(
        map((measures) =>
          this.setMeasures(measures)
        ))));
  });

  readonly selectFactoredMeasures = this.select((state) => state.factoredMeasures)
    .pipe(filter((fm) => !!fm.length))
    .pipe(take(1));

  readonly getFactoredMeasures = this.effect((input$: Observable<void>) => {
    return input$.pipe(
      switchMap(() => this.measureService.getFactoredMeasures().pipe(
        map((measures) =>
          this.setFactoredMeasures(measures)
        ))));
  });

  readonly setAllMeasuresAreFactorable = this.updater((state, allMeasuresAreFactorable: boolean) => ({
    ...state,
    allMeasuresAreFactorable
  }));

  setMeasures(measures: MeasureModel[]): void {
    this.setState((state) => ({
      ...state,
      measures: [...measures]
    }));
  }

  setFactoredMeasures(factoredMeasureModels: FactoredMeasureModel[]): void {
    this.setState((state) => ({
      ...state,
      factoredMeasures: [...factoredMeasureModels]
    }));
  }
}
