import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {Config} from '../models';
import {RootStore} from '../store/root.store';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  config: Config;
  constructor(private readonly rootStore: RootStore) {
    rootStore.getConfig.subscribe((c) => this.config = c);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const headers = request.headers
      .set('Authorization', `Bearer ${this.config.userContext.bearerToken}`)
      .set('organisationid', `${this.config.userContext.organisationId}`);
    const reqClone = request.clone({
      headers, withCredentials: true
    });
    return next.handle(reqClone);
  }
}
