import {ComponentStore} from '@ngrx/component-store';
import {Observable} from 'rxjs';
import {filter, take} from 'rxjs/operators';
import {AttributeCriteriaModel, CriteriaModel, UomCriteriaModel} from '../models';
import {AttributeModel} from '../models/attribute.model';
import {OperatorChanged} from '../models/operator-changed.model';

export interface CriteriaBuilderState {
  criteriaList: { [id: number]: CriteriaModel };
}

export const InitialState: CriteriaBuilderState = {
  criteriaList: {}
};

export class CriteriaBuilderStore extends ComponentStore<CriteriaBuilderState> {

  constructor() {
    super(InitialState);
  }

  readonly addCriteria = this.updater((state, criteria: CriteriaModel) => ({
    criteriaList: {
      ...state.criteriaList, [criteria.id]: criteria
    }
  }));

  // Why can't I use the CriteriaDictionary interface as the parameter type?!?!?!?!?!?!?!
  readonly setStateFromModel = this.updater((state, criteriaDictionary: { [id: number]: CriteriaModel }) => ({
    criteriaList: criteriaDictionary
  }));

  readonly updateUomCriteria = this.updater((state, uomCriteria: UomCriteriaModel) => ({
    criteriaList: {
      ...state.criteriaList,
      [uomCriteria.criteriaId]: {
        ...state.criteriaList[uomCriteria.criteriaId], uomCriteria: {...uomCriteria}
      }
    }
  }));

  readonly addAttributeCriteria = this.updater((state, attributeCriteria: AttributeCriteriaModel) => ({
    criteriaList: {
      ...state.criteriaList,
      [attributeCriteria.criteriaId]: {
        ...state.criteriaList[attributeCriteria.criteriaId],
        attributeCriteria: {
          ...state.criteriaList[attributeCriteria.criteriaId].attributeCriteria,
          [attributeCriteria.id]: {
            ...state.criteriaList[attributeCriteria.criteriaId].attributeCriteria[attributeCriteria.id],
            ...attributeCriteria
          }
        }
      }
    }
  }));

  readonly updateAttributesOnCriteria = this.updater((state, newAttribute: AttributeModel) => ({
    criteriaList: {
      ...state.criteriaList,
      [newAttribute.criteriaId]: {
        ...state.criteriaList[newAttribute.criteriaId],
        attributeCriteria: {
          ...state.criteriaList[newAttribute.criteriaId].attributeCriteria,
          [newAttribute.attributeCriteriaId]: {
            ...state.criteriaList[newAttribute.criteriaId].attributeCriteria[newAttribute.attributeCriteriaId],
            attributes: [
              ...state.criteriaList[newAttribute.criteriaId].attributeCriteria[newAttribute.attributeCriteriaId].attributes,
              {...newAttribute}
            ]
          }
        }
      }
    }
  }));

  readonly removeAttribute = this.updater((state, attributeToRemove: AttributeModel) => ({
    criteriaList: {
      ...state.criteriaList,
      [attributeToRemove.criteriaId]: {
        ...state.criteriaList[attributeToRemove.criteriaId],
        attributeCriteria: {
          ...state.criteriaList[attributeToRemove.criteriaId].attributeCriteria,
          [attributeToRemove.attributeCriteriaId]: {
            ...state.criteriaList[attributeToRemove.criteriaId].attributeCriteria[attributeToRemove.attributeCriteriaId],
            attributes: [
              ...state.criteriaList[attributeToRemove.criteriaId].attributeCriteria[attributeToRemove.attributeCriteriaId].attributes
                .filter(x => x.id !== attributeToRemove.id)
            ]
          }
        }
      }
    }
  }));

  // tslint:disable-next-line:max-line-length
  readonly updateOperator = this.updater((state, operatorToUpdate: OperatorChanged) => ({
    criteriaList: {
      ...state.criteriaList,
      [operatorToUpdate.criteriaId]: {
        ...state.criteriaList[operatorToUpdate.criteriaId],
        attributeCriteria: {
          ...state.criteriaList[operatorToUpdate.criteriaId].attributeCriteria,
          [operatorToUpdate.attributeCriteriaId]: {
            ...state.criteriaList[operatorToUpdate.criteriaId].attributeCriteria[operatorToUpdate.attributeCriteriaId],
            operator: operatorToUpdate.operator
          }
        }
      }
    }
  }));

  removeCriteria = this.updater((state: CriteriaBuilderState, id: number) => {
    const newCriteriaList = {...state};
    delete newCriteriaList.criteriaList[id];
    return newCriteriaList;
  });

  removeAttributeCriteria = this.updater((state, attributeCriteria: AttributeCriteriaModel) => {
    const newState = {...state};
    delete newState.criteriaList[attributeCriteria.criteriaId].attributeCriteria[attributeCriteria.id];
    return newState;
  });

  getCriteriaState(): Observable<{ [id: number]: CriteriaModel }> {
    return this.select((state) => state.criteriaList)
      .pipe(filter((c) => !!c))
      .pipe(take(1));
  }

  getAttributeCriteriaKeys(criteriaId: number): Observable<string[]> {
    return this.select((state) => Object.keys(state.criteriaList[criteriaId].attributeCriteria));
  }

  getAttributeIdsForAttributeCriteria(criteriaId: number, attributeCriteriaId: number): Observable<number[]> {
    return this.select((state) => state.criteriaList[criteriaId].attributeCriteria[attributeCriteriaId].attributes.map(x => x.id));
  }

  resetState(): void {
    this.setState(InitialState);
  }
}
