import {TranslatableAttribute} from '../classes/translatable-attribute';

export class SkuAttributeModel extends TranslatableAttribute {
  constructor(attribute: any) {
    super();
    this.id = attribute.id;
  }

  id: number;
  attributeGroupName: string;
  attributeName: string;
}
