import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {SearchHeaderModel} from '../../models';
import {RootStore} from '../../store/root.store';
import {SearchHeaderStore} from '../../store/search-header.store';

@Component({
  selector: 'cdb-advanced-search-header-container',
  templateUrl: './advanced-search-header-container.component.html',
  styleUrls: ['./advanced-search-header-container.component.css']
})
export class AdvancedSearchHeaderContainerComponent implements OnInit {
  searchHeader$ = this.searchHeaderStore.select((state) => state.searchHeader);
  @Output() doSearch = new EventEmitter<any>();
  returnUrl$ = this.rootStore.select((state) => state.returnUrl);

  constructor(private readonly searchHeaderStore: SearchHeaderStore,
              private readonly rootStore: RootStore) {
  }

  ngOnInit(): void {
  }

  emitDoSearch(): void {
    this.doSearch.emit();
  }

  updateHeaderChange(searchHeader: SearchHeaderModel): void {
    this.searchHeaderStore.updateHeader(searchHeader);
  }
}
