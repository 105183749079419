<div fxLayout="row"
     fxLayoutAlign="end center"
     class="cdb-sku-save-bar card"
>
  <p fxFlex
     *ngIf="savedSearchType === savedSearchTypeEnum.advancedSearchUseRecommended"
     class="cdb-sku-save-label mat-warn"><span class="fa fa-warning"></span> {{'This option will save the currently best priced SKU This SKU could change at any time if pricing changes' | translate }}</p>
  <mat-form-field>
    <mat-select [(ngModel)]="savedSearchType"
                (ngModelChange)="updateSkuSaveOption()"
                [panelClass]="'cdb-select-open-up'"
                [disabled]="disableSelect"
    >
      <mat-option [value]="savedSearchTypeEnum.advancedSearchExactSku">{{'Use selected SKU' | translate }}</mat-option>
      <mat-option [value]="savedSearchTypeEnum.advancedSearchUseRecommended">{{'Always use top SKU' | translate }}</mat-option>
    </mat-select>
  </mat-form-field>

  <button class="cdb-sku-save-button"
          [disabled]="disableSaveButton()"
          (click)="emitSaveSku()"
  >
    {{'Save' | translate }}
    <i class="fa fa-spinner fa-spin" *ngIf="skusSaving"></i>
  </button>
</div>
