<div fxLayout="row"
>
  <form fxLayout="column"
        [formGroup]="headerFormGroup">
    <input type="text"
           autofocus
           [placeholder]="'Search name' | translate"
           [formControlName]="'name'"
           required
    />
    <p class="mat-error margin-left-10"
       *ngIf="headerFormGroup.get('name').touched && headerFormGroup.get('name').invalid">{{ 'Please enter a name for the search' | translate }}</p>

    <div>
      <mat-checkbox class="width-auto"
                    [formControlName]="'isUsedInRecipe'"
      >{{ 'Used in Recipe' | translate }}
      </mat-checkbox>
      <mat-checkbox class="width-auto"
                    [formControlName]="'isValVol'"
      >{{ 'Val/Vol' | translate }}
      </mat-checkbox>
      <mat-checkbox class="width-auto"
                    [formControlName]="'isFavourite'"
      >{{ 'Favourites' | translate }}
      </mat-checkbox>
      <mat-checkbox class="width-auto"
                    [formControlName]="'isUsedInListOrGuide'"
      >{{ 'Used in Lists/Guides' | translate }}
      </mat-checkbox>
      <mat-form-field>
        <mat-select [formControlName]="'priceRank'">
          <mat-option [value]="priceRankEnum.bestPrice">{{'Best Price (Factored)' | translate }}</mat-option>
          <mat-option [value]="priceRankEnum.portion">{{'Best Price (Portion)' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
  <span fxFlex></span>
  <a class="button secondary cdb-search-header-back"
     [href]="returnUrl"
     fxFlexAlign="start margin-0">{{'Back' | translate }}</a>
</div>
