import {Injectable} from '@angular/core';
import {ComponentStore} from '@ngrx/component-store';
import {SearchRankTypeEnum} from '../enums';
import {SearchHeaderModel} from '../models';

export interface SearchHeaderState {
  searchHeader: SearchHeaderModel;
}

export const InitialState: SearchHeaderState = {
  searchHeader: {
    name: null,
    isFavourite: false,
    isValVol: false,
    isUsedInRecipe: false,
    isUsedInListOrGuide: false,
    priceRank: SearchRankTypeEnum.bestPrice
  }
};

@Injectable()
export class SearchHeaderStore extends ComponentStore<SearchHeaderState> {
  constructor() {
    super(InitialState);
  }

  readonly updateHeader = this.updater((state, searchHeader: SearchHeaderModel) => ({
    searchHeader
  }));

  setSearchHeader(searchHeader: SearchHeaderModel): void {
    this.setState((state) => ({
      searchHeader
    }));
  }

  setPriceRank(priceRank: SearchRankTypeEnum): void {
    this.setState((state) => ({
      state,
      searchHeader: {
        ...state.searchHeader,
        priceRank
      }
    }));
  }

  resetState(): void {
    this.setState(InitialState);
  }
}
