import {MeasureModel} from './';

export class UomCriteriaModel {
  constructor(criteriaId: number) {
    this.criteriaId = criteriaId;
  }
  criteriaId: number;

  unitFrom: number;
  unitTo: number;

  unitFromMeasure: MeasureModel;
  unitToMeasure: MeasureModel;

  caseFrom: number;
  caseTo: number;

  packFrom: number;
  packTo: number;
}
