import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {BasketTypeEnum, SavedSearchTypeEnum, SearchRankTypeEnum} from '../../enums';
import {FactoredMeasureModel, MeasureModel, SearchHeaderModel, SkuAttributeModel, SkuModel, SortModel} from '../../models';
import {AttributeModel} from '../../models/attribute.model';
import {FactoredMeasuresService} from '../../services';

@Component({
  selector: 'cdb-sku-results',
  templateUrl: './sku-results.component.html',
  styleUrls: ['./sku-results.component.css']
})
export class SkuResultsComponent implements OnInit, AfterViewInit {
  @Input() allMeasuresAreFactorable: boolean;
  @Input() entityType: BasketTypeEnum;
  @Input() language: string;
  @Input() measures: MeasureModel[];
  @Input() savedSearchType: SavedSearchTypeEnum;
  @Input() searchHeader: SearchHeaderModel;
  @Input() searchMeasureId: number;
  @Input() supplierAttributeGroupId: number;
  @Input() disableSaveBarSelect: boolean;
  @Input() skus: SkuModel[] = [];
  @Input() skusSaving: boolean;

  @Output() sort = new EventEmitter<SortModel>();
  @Output() saveSku = new EventEmitter<SkuModel>();


  @ViewChild(MatSort) tableSort: MatSort;

  displayedColumns = [
    'SkuSupplier',
    'SkuDescription',
    'SkuCode',
    'SkuAttributes',
    'SkuPrice',
    'FactoredPrice',
    'actions'
  ];
  entityTypes: typeof BasketTypeEnum = BasketTypeEnum;
  savedSearchTypeEnum: typeof SavedSearchTypeEnum = SavedSearchTypeEnum;
  priceRankEnum: typeof SearchRankTypeEnum = SearchRankTypeEnum;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.tableSort.sortChange
      .pipe()
      .subscribe(() => {
        console.log(this.tableSort);
        this.sort.emit({
          active: this.tableSort.active,
          direction: this.tableSort.direction
        } as SortModel);
      });
  }

  emitSaveSku(sku: SkuModel): void {
    if (!sku) { sku = this.skus.find((s) => s.isBestPrice); }
    this.saveSku.emit(sku);
  }

  public getMeasure(skuMeasureId: number): string {
    const measure = this.measures.find((m) => m.id === skuMeasureId);
    if (measure === null || measure === undefined) {
      return;
    }

    return measure.name;
  }

  translateAttributeText(attribute: SkuAttributeModel, attributeName: keyof SkuAttributeModel): string {
    return attribute[`${attributeName}_${this.normaliseLanguageCode(this.language)}`];
  }

  private normaliseLanguageCode(language: string): string {
    return language.replace('-', '_');
  }

  getSupplierAttributeName(productAttributes: AttributeModel[]): string {
    return productAttributes.find((attribute) => attribute.attributeGroupId === this.supplierAttributeGroupId)?.productAttributeDescription;
  }

  filterAttributes(productAttributes: AttributeModel[]): AttributeModel[] {
    return productAttributes.filter((attr) => attr.attributeGroupId !== this.supplierAttributeGroupId);
  }

  getSkuFactoredPrice(row: SkuModel): string {
    return this.searchHeader.priceRank === SearchRankTypeEnum.bestPrice ?
      row.priceFactoredUpliftedPriceToNormalisedMeasure.toFixed(5) :
      row.pricePortionPrice.toFixed(5);
  }

  displaySkuSaveBar(): boolean {
    return this.skus && this.skus.length && this.entityType === this.entityTypes.OrderGuide && this.searchMeasureId !== null;
  }

  disableSave(): boolean {
    return !this.allMeasuresAreFactorable ||
      this.skusSaving ||
      !this.searchHeader.name ||
      (this.searchHeader.name && !this.searchHeader.name.length);
  }
}
