import {OperatorEnum} from '../enums';

export class OperatorChanged {
  constructor(operator: OperatorEnum, attributeCriteriaId: number) {
    this.operator = operator;
    this.attributeCriteriaId = attributeCriteriaId;
  }

  operator: OperatorEnum;
  attributeCriteriaId: number;
  criteriaId: number;

}
