import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {error} from 'ng-packagr/lib/utils/log';
import {ToastrService} from 'ngx-toastr';
import {EMPTY, Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private toastr: ToastrService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(catchError((err => {
        const errorMessage = `[${err.status}]  ${err.error?.exceptionMessage ?? err.message}`;
        this.toastr.error(errorMessage);
        return EMPTY;
      })));
  }
}
