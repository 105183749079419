<mat-chip-list #attributeChipList>
  <ng-container *ngFor="let attribute of attributes; first as first">
    <span class="cdb-attribute-tag-operator-text"
          *ngIf="attributes.length > 1 && !first">{{getAttributeOperatorText(attribute)}}</span>
    <mat-chip [removable]="true"
              (removed)="emitRemoveAttribute(attribute)"
    >{{attribute.productAttributeDescription}} <span class="cdb-attribute-group-name">{{attribute.attributeGroupDescription}}</span>
      <input type="checkbox"
             [(ngModel)]="attribute.isInclude"
             (ngModelChange)="emitAttributeIsIncludeChange(attribute.isInclude)"
             id="{{attribute.attributeCriteriaId}}-{{attribute.id}}"
      />
      <label for="{{attribute.attributeCriteriaId}}-{{attribute.id}}" class="cdb-attribute-checkbox"></label>
      <a class="fa fa-close" matChipRemove></a>
    </mat-chip>
  </ng-container>
  <input type="text"
         matInput
         #attributeInput
         (focusin)="attributeSearchFocused($event)"
         [matAutocomplete]="attributeAutocomplete"
         [matChipInputFor]="attributeChipList"
         (input)="emitAttributeSearchTermChanged($event)"/>
</mat-chip-list>
<mat-autocomplete #attributeAutocomplete="matAutocomplete"
                  (optionSelected)="emitAttributeSelected($event)"
                  [displayWith]="displayWith"
>
  <mat-option *ngFor="let attribute of attributes$ | async"
              [value]="attribute"
  >
    {{attribute.productAttributeName}} <span class="cdb-attribute-group-hint">{{attribute.attributeGroupName}}</span>
  </mat-option>
</mat-autocomplete>
