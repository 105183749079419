import {HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {Config, FactoredMeasureModel, MeasureModel} from '../models';
import {RootStore} from '../store/root.store';

@Injectable()
export class MeasureService {
  private config: Config;

  constructor(private readonly httpClient: HttpClient,
              private readonly rootStore: RootStore) {
    this.rootStore.getConfig
      .subscribe((config: Config) => {
        this.config = config;
      });
  }

  search(): Observable<MeasureModel[]> {
    return this.httpClient.get<MeasureModel[]>(this.getMeasureUrl());
  }

  private getMeasureUrl(): string {
    return this.config.endpoints.measuresEndpoint;
  }

  getFactoredMeasures(): Observable<FactoredMeasureModel[]> {
    return this.httpClient.get<FactoredMeasureModel[]>(this.config.endpoints.factoredMeasuresEndpoint);
  }
}
