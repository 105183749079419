import {Injectable} from '@angular/core';
import {filter, take} from 'rxjs/operators';
import {FactoredMeasureModel} from '../models';
import {MeasureStore} from '../store/measure.store';
import {cdbIsNullOrUndefined} from '../utilities';

@Injectable()
export class FactoredMeasuresService {
  factoredMeasures: FactoredMeasureModel[];

  constructor(private readonly measuresStore: MeasureStore) {
    this.measuresStore.select((state) => state.factoredMeasures)
      .pipe(filter(m => {
        return !!m && !!m.length;
      }))
      .pipe(take(1))
      .subscribe((fm) => this.factoredMeasures = fm);
  }

  areMeasuresFactorable(measuresToCheck: { measureFrom: number; measureTo: number }[]): boolean {
    const allMeasuresAreFactorable = measuresToCheck.every((um) => this.factoredMeasures
      .find((fm) =>
        um.measureFrom === fm.sourceMeasureId && um.measureTo === fm.targetMeasureId
      ));
    console.log('all measures are factorable');
    return allMeasuresAreFactorable;
  }
}
