import {SearchRankTypeEnum} from '../enums';

export class SearchHeaderModel {
  name: string;
  isFavourite: boolean;
  isValVol: boolean;
  isUsedInListOrGuide: boolean;
  isUsedInRecipe: boolean;
  priceRank: SearchRankTypeEnum = SearchRankTypeEnum.bestPrice;
}
