import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {OrderGuideType} from '../../enums';
import {SaveServiceFactory} from '../../factories';
import {SaveService} from '../../interfaces';
import {SkuModel, SortModel} from '../../models';
import {MeasureStore} from '../../store/measure.store';
import {RootStore} from '../../store/root.store';
import {SearchHeaderStore} from '../../store/search-header.store';
import {SkuStore} from '../../store/sku.store';

@Component({
  selector: 'cdb-sku-results-container',
  templateUrl: './sku-results-container.component.html',
  styleUrls: ['./sku-results-container.component.css']
})
export class SkuResultsContainerComponent implements OnInit {
  @Output() doSearch = new EventEmitter<void>();

  allMeasuresAreFactorable$ = this.measureStore.select((state) => state.allMeasuresAreFactorable);
  config$ = this.rootStore.select((state) => state.config);
  measures$ = this.measureStore.select((state) => state.measures);
  searchMeasureId$ = this.skuStore.select((state) => state.measureId);
  skus$ = this.skuStore.select((state) => state.results);
  skusSaving$ = this.skuStore.select((store) => store.skuSaving);
  skusLoading$ = this.skuStore.select((state) => state.skusLoading);
  savedSearchType$ = this.skuStore.select((state) => state.saveOption);
  searchHeader$ = this.searchHeaderStore.select((state) => state.searchHeader);
  saveService: SaveService;
  orderGuideTypes: typeof OrderGuideType = OrderGuideType;

  constructor(private readonly skuStore: SkuStore,
              private readonly saveServiceFactory: SaveServiceFactory,
              private readonly rootStore: RootStore,
              private readonly measureStore: MeasureStore,
              private readonly searchHeaderStore: SearchHeaderStore) {
  }

  ngOnInit(): void {
    this.saveService = this.saveServiceFactory.getService();
  }

  saveSku(sku: SkuModel): void {
    this.skuStore.saveSku(sku);
  }

  emitSearch(): void {
    this.doSearch.emit();
  }

  doSort($event: SortModel): void {
    this.skuStore.updateSkuSort($event);
  }
}
