import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {Injectable} from '@angular/core';
import {AdvancedSearchDto, AttributeCriteriaDto, CriteriaDto, SearchCriteriaDto, SearchDto} from '../dtos';
import {UomCriteriaDto} from '../dtos/uom-criteria.dto';
import {UomTypeEnum} from '../enums/uomType.enum';
import {CriteriaDictionary} from '../interfaces';
import {
  AttributeCriteriaModel,
  AzureAttributeModel,
  Config,
  CriteriaModel,
  MeasureModel,
  SearchHeaderModel,
  SortModel,
  UomCriteriaModel
} from '../models';
import {AttributeModel} from '../models/attribute.model';
import {LanguageUtil} from '../utilities';

@Injectable()
export class CriteriaTransformerService {

  constructor() {
  }

  // TODO: Map search header
  mapToDto(criteriaModels: { [p: number]: CriteriaModel },
           searchHeader: SearchHeaderModel,
           config: Config,
           measureId?: number,
           skuSort?: SortModel): AdvancedSearchDto {
    const dto: AdvancedSearchDto = {
      searchCriteria: new SearchCriteriaDto(searchHeader.priceRank),
      getPricesAt: new Date(),
      languageCode: LanguageUtil.normaliseLanguage(config.userContext.language),
      resultSize: 1
    };
    if (skuSort !== null && skuSort.direction !== '') {
      dto.columnRank = skuSort.active;
      dto.columnSort = skuSort.direction;
    } else {
      dto.columnRank = null;
    }

    dto.searchCriteria.isFavourite = searchHeader.isFavourite;
    dto.searchCriteria.isUsedInRecipe = searchHeader.isUsedInRecipe;
    dto.searchCriteria.isUsedInListOrGuide = searchHeader.isUsedInListOrGuide;
    dto.searchCriteria.isValVol = searchHeader.isValVol;
    dto.searchCriteria.clientId = config.userContext.clientId;
    // Wrapped in an if to prevent passing nulls to the server.
    if (measureId !== null) {
      dto.searchCriteria.measureId = measureId;
    }
    Object.keys(criteriaModels).forEach((criteriaModelKey: string) => {
      const criteriaDto = new CriteriaDto();
      const uomCriteriaDtos: UomCriteriaDto[] = [];
      const modelUomCriteria = criteriaModels[criteriaModelKey].uomCriteria;
      if (modelUomCriteria != null) {
        if (modelUomCriteria.unitFrom != null &&
          modelUomCriteria.unitTo != null &&
          modelUomCriteria.unitFromMeasure != null &&
          modelUomCriteria.unitFromMeasure.id !== 0 &&
          modelUomCriteria.unitToMeasure != null &&
          modelUomCriteria.unitToMeasure.id !== 0) {
          const uomCriteriaDto = new UomCriteriaDto();
          uomCriteriaDto.leftMeasureId = modelUomCriteria.unitFromMeasure.id;
          uomCriteriaDto.rightMeasureId = modelUomCriteria.unitToMeasure.id;
          uomCriteriaDto.leftValue = modelUomCriteria.unitFrom;
          uomCriteriaDto.rightValue = modelUomCriteria.unitTo;
          uomCriteriaDto.field = UomTypeEnum.Unit;
          uomCriteriaDtos.push(uomCriteriaDto);
        }
        if (modelUomCriteria.caseFrom != null &&
          modelUomCriteria.caseTo != null) {
          const uomCriteriaDto = new UomCriteriaDto();
          uomCriteriaDto.leftValue = modelUomCriteria.caseFrom;
          uomCriteriaDto.rightValue = modelUomCriteria.caseTo;
          uomCriteriaDto.field = UomTypeEnum.Case;
          uomCriteriaDtos.push(uomCriteriaDto);
        }
        if (modelUomCriteria.packFrom != null &&
          modelUomCriteria.packTo != null) {
          const uomCriteriaDto = new UomCriteriaDto();
          uomCriteriaDto.leftValue = modelUomCriteria.packFrom;
          uomCriteriaDto.rightValue = modelUomCriteria.packTo;
          uomCriteriaDto.field = UomTypeEnum.Pack;
          uomCriteriaDtos.push(uomCriteriaDto);
        }
        criteriaDto.uomAnd = uomCriteriaDtos;
      }
      Object.keys(criteriaModels[criteriaModelKey].attributeCriteria).forEach((key) => {
        const modelAttributeCriteria = criteriaModels[criteriaModelKey].attributeCriteria[key];
        if (modelAttributeCriteria && modelAttributeCriteria.attributes && modelAttributeCriteria.attributes.length) {
          const attributeAnd = new AttributeCriteriaDto();
          attributeAnd.id = +key;
          attributeAnd.operator = modelAttributeCriteria.operator;
          attributeAnd.attributes = {};
          modelAttributeCriteria.attributes.forEach((attribute: AttributeModel) => {
            attributeAnd.attributes[attribute.id] = String(attribute.isInclude);
          });
          criteriaDto.attributeAnd.push(attributeAnd);
        }
      });

      // Only append criteria dto if we have attributes in our criteria block
      if (criteriaDto.attributeAnd.length) {
        dto.searchCriteria.criteriaOr.push(criteriaDto);
      }
    });
    // TODO this is temporary hard coded
    // date.setDate(date.getDate() - 30);
    dto.getPricesAt = new Date();
    return dto;
  }

  mapToModel(dto: SearchDto, azureAttributes: AzureAttributeModel[]): CriteriaDictionary {
    const criteriaDictionary: CriteriaDictionary = {criteria: {}};
    dto.searchCriteria.criteriaOr.forEach((criteriaOr, criteriaOrKey) => {
      criteriaDictionary.criteria[criteriaOrKey] = new CriteriaModel();
      criteriaDictionary.criteria[criteriaOrKey].id = criteriaOrKey; // TODO This may need to change during edit scenario

      criteriaDictionary.criteria[criteriaOrKey].uomCriteria = new UomCriteriaModel(criteriaOrKey);
      if (criteriaOr.uomAnd) {
        criteriaOr.uomAnd.forEach((uomCriteriaDto) => {
          if (uomCriteriaDto.leftValue != null && uomCriteriaDto.rightValue != null && uomCriteriaDto.field != null) {
            if (uomCriteriaDto.field === UomTypeEnum.Case) {
              criteriaDictionary.criteria[criteriaOrKey].uomCriteria.caseFrom = uomCriteriaDto.leftValue;
              criteriaDictionary.criteria[criteriaOrKey].uomCriteria.caseTo = uomCriteriaDto.rightValue;
            } else if (uomCriteriaDto.field === UomTypeEnum.Pack) {
              criteriaDictionary.criteria[criteriaOrKey].uomCriteria.packFrom = uomCriteriaDto.leftValue;
              criteriaDictionary.criteria[criteriaOrKey].uomCriteria.packTo = uomCriteriaDto.rightValue;
            } else if (uomCriteriaDto.field === UomTypeEnum.Unit && uomCriteriaDto.leftMeasureId && uomCriteriaDto.rightMeasureId) {
              criteriaDictionary.criteria[criteriaOrKey].uomCriteria.unitFrom = uomCriteriaDto.leftValue;
              criteriaDictionary.criteria[criteriaOrKey].uomCriteria.unitTo = uomCriteriaDto.rightValue;
              criteriaDictionary.criteria[criteriaOrKey].uomCriteria.unitFromMeasure = new MeasureModel(uomCriteriaDto.leftMeasureId, '');
              criteriaDictionary.criteria[criteriaOrKey].uomCriteria.unitToMeasure = new MeasureModel(uomCriteriaDto.rightMeasureId, '');
            }
          }
        });
      }

      criteriaOr.attributeAnd.forEach((attributeAnd, attributeAndKey) => {
        // tslint:disable-next-line:max-line-length
        criteriaDictionary.criteria[criteriaOrKey].attributeCriteria[attributeAndKey] =
          new AttributeCriteriaModel(criteriaOrKey, attributeAnd.operator, attributeAnd.id);
        Object.keys(attributeAnd.attributes).forEach((attributeKey) => {
          const attributeId = +attributeKey;
          const attributeCriteria = {};
          attributeCriteria[attributeAndKey] = {};
          const attributeFromAzure = azureAttributes.find((a) => a.id === attributeId);
          let attributeModel: AttributeModel;
          if (attributeFromAzure) {
            attributeModel = new AttributeModel(criteriaOrKey, attributeAndKey, attributeFromAzure);
          } else {
            attributeModel = new AttributeModel(criteriaOrKey, attributeAndKey);
            attributeModel.id = parseInt(attributeKey, null);
          }
          attributeModel.isInclude = coerceBooleanProperty(attributeAnd.attributes[attributeKey]);
          criteriaDictionary.criteria[criteriaOrKey].attributeCriteria[attributeAndKey].attributes.push(attributeModel);
        });
      });
    });

    return criteriaDictionary;
  }
}
