import {TranslatableSku} from '../classes';
import {SkuAttributeModel} from './sku-attribute-model';

export class SkuModel extends TranslatableSku {
  constructor(skuModel: SkuModel) {
    super();
    this.priceCurrencySymbol = skuModel.priceCurrencySymbol;
    this.priceClientSupplierAccountID = skuModel.priceClientSupplierAccountID;
    this.key = skuModel.key;
    this.skuId = skuModel.skuId;
    this.unit = skuModel.unit;
    this.packQty = skuModel.packQty;
    this.caseQty = skuModel.caseQty;
    this.skuMeasureId = skuModel.skuMeasureId;
    this.skuCode = skuModel.skuCode;
    this.attributes = skuModel.attributes;
    this.isBestPrice = skuModel.isBestPrice;
  }

  isBestPrice: boolean;
  priceCurrencySymbol: string;
  priceFactoredUpliftedPriceToNormalisedMeasure: number;
  pricePortionPrice: number;
  priceClientSupplierAccountID: number;
  key: string;
  skuId: number;
  unit: number;
  packQty: number;
  caseQty: number;
  skuMeasureId: number;
  priceItemPrice: number;
  skuCode: string;
  attributes: SkuAttributeModel[];
}
