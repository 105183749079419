import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {TranslateService} from '@ngx-translate/core';
import {OperatorEnum} from '../../enums';
import {AzureAttributeModel} from '../../models';
import {AttributeModel} from '../../models/attribute.model';
import {AttributeStore} from '../../store/attribute.store';

@Component({
  selector: 'cdb-attribute-tags',
  templateUrl: './attribute-tags.component.html',
  styleUrls: ['./attribute-tags.component.css']
})
export class AttributeTagsComponent implements OnInit {
  @Input() attributes: AttributeModel[];
  @Input() attributeOperator: OperatorEnum;
  @Output() attributeIsIncludeChanged = new EventEmitter<boolean>();
  @Output() attributeSelected = new EventEmitter<any>();
  @Output() attributeSearchTermChanged = new EventEmitter<string>();
  @Output() clearAttributeSearch = new EventEmitter();
  @Output() removeAttribute = new EventEmitter<AttributeModel>();
  @ViewChild('attributeInput') attributeInput: ElementRef<HTMLInputElement>;
  attributes$ = this.attributeStore.select((state) => state.attributes);
  attributeOperatorEnum: typeof OperatorEnum = OperatorEnum;
  marker = marker;
  constructor(private readonly attributeStore: AttributeStore,
              private readonly translateService: TranslateService) { }

  ngOnInit(): void {
  }

  emitRemoveAttribute(attribute: AttributeModel): void {
    this.removeAttribute.emit(attribute);
  }

  attributeSearchFocused($event: FocusEvent): void {
    const value = ($event.target as HTMLInputElement).value;
    value ? this.emitAttributeSearchTermChanged($event) : this.emitClearAttributeSearch();
  }

  emitAttributeSelected($event: MatAutocompleteSelectedEvent): void {
    this.attributeSelected.emit($event.option.value);
    this.attributeInput.nativeElement.value = '';
  }

  emitAttributeSearchTermChanged($event: Event): void {
    this.attributeSearchTermChanged.emit(($event.target as HTMLInputElement).value);
  }

  emitClearAttributeSearch(): void {
    this.clearAttributeSearch.emit();
  }

  emitAttributeIsIncludeChange(isInclude: boolean): void {
    this.attributeIsIncludeChanged.emit(isInclude);
  }

  displayWith(value: AzureAttributeModel): string {
    return value.productAttributeName;
  }

  getAttributeOperatorText(attribute: AttributeModel): string {
    let translationKey: string;
    const translationKeys = {
      and: marker('and'),
      or: marker('or'),
      andNot: marker('and not'),
      orNot: marker('or not'),
    };
    if (this.attributeOperator === OperatorEnum.All) {
      translationKey = attribute.isInclude ? translationKeys.and : translationKeys.andNot;
    } else {
      translationKey = attribute.isInclude ? translationKeys.or : translationKeys.orNot;
    }

    return this.translateService.instant(translationKey);
  }
}
