import {Injectable} from '@angular/core';
import {ComponentStore} from '@ngrx/component-store';
import {filter, take} from 'rxjs/operators';
import {SavedSearch} from '../interfaces/saved.search.interface';
import {Config} from '../models';

export interface RootState {
  config: Config;
  savedSearch: SavedSearch;
  returnUrl: string;
}

const InitialState: RootState = {
  config: null,
  savedSearch: null,
  returnUrl: null
};

@Injectable()
export class RootStore extends ComponentStore<RootState> {
  constructor() {
    super(InitialState);
  }

  readonly getConfig = this.select((state) => state.config)
    .pipe(filter(c => !!c))
    .pipe(take(1));

  readonly getSavedSearch = this.select((state) => state.savedSearch)
    .pipe(filter(c => !!c))
    .pipe(take(1));

  readonly setSavedSearch = this.updater((state, savedSearch: SavedSearch) => ({
    ...state,
    savedSearch
  }));

  readonly setReturnUrl = this.updater((state, returnUrl: string) => ({
    ...state,
    returnUrl
  }));

  readonly setConfig = (config: Config) => this.setState((state) => ({
    ...state,
    config: {...config}
  }))

  resetState(): void {
    this.setState(InitialState);
  }
}
