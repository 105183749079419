<cdb-sku-results [allMeasuresAreFactorable]="allMeasuresAreFactorable$ | async"
                 [language]="(config$ | async).userContext.language"
                 [measures]="measures$ | async"
                 [searchMeasureId]="searchMeasureId$ | async"
                 [savedSearchType]="savedSearchType$ | async"
                 [supplierAttributeGroupId]="(config$ | async).dynamicEnums.attributeGroups.supplier"
                 [disableSaveBarSelect]="(config$ | async).entityContext.additionalData?.sheetType === orderGuideTypes.OrderSheet"
                 [entityType]="(config$ | async).entityContext.entityType"
                 [searchHeader]="searchHeader$ | async"
                 [skus]="skus$ | async"
                 [skusSaving]="skusSaving$ | async"
                 (sort)="doSort($event)"
                 (saveSku)="saveSku($event)"
></cdb-sku-results>
<div class="loading"
     fxLayout="row"
     fxLayoutAlign="center center"
     *ngIf="skusLoading$ | async"
>
  <i class="fa fa-spinner fa-spin"></i>
</div>
