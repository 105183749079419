import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Config} from '../../models';
import {CriteriaBuilderStore} from '../../store/criteria-builder.store';
import {MeasureStore} from '../../store/measure.store';
import {RootStore} from '../../store/root.store';
import {SearchHeaderStore} from '../../store/search-header.store';
import {SkuStore} from '../../store/sku.store';

@Component({
  selector: 'cdb-advanced-search-container',
  templateUrl: './advanced-search-container.component.html',
  styleUrls: ['./advanced-search-container.component.css'],
  providers: [
    SkuStore
  ]
})
export class AdvancedSearchContainerComponent implements OnInit, OnDestroy {
  @Input() config: Config;

  constructor(private readonly criteriaBuilderStore: CriteriaBuilderStore,
              private readonly rootStore: RootStore,
              private readonly skuStore: SkuStore,
              private readonly translate: TranslateService,
              private readonly searchHeaderStore: SearchHeaderStore,
              private readonly measureStore: MeasureStore) {
    translate.addLangs([
      'de',
      'fr',
      'it',
      'nl',
      'en',
      'fr_be',
      'nl_be',
      'es_es'
    ]);
    translate.setDefaultLang('en');
  }

  ngOnInit(): void {
    this.rootStore.setConfig(this.config);
    this.measureStore.search();
    this.measureStore.getFactoredMeasures();
    this.translate.use(this.config.userContext.language);
    this.skuStore.select((state) => state.skuSort)
      .subscribe(() => this.doSearch());
  }

  ngOnDestroy(): void {
    this.criteriaBuilderStore.resetState();
    this.skuStore.resetState();
    this.rootStore.resetState();
    this.searchHeaderStore.resetState();
  }

  /*
  * This is being called by Output() events down the stack as I tried to use the store selectors to do a search on change.
  * This didn't work with child properties (eg. Any/All operator and IsInclude)
  * We should revisit this to try and perform a search on criteria change.
  * */
  doSearch(): void {
    this.skuStore.search(this.criteriaBuilderStore.getCriteriaState());
  }
}
