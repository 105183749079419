import {Injectable} from '@angular/core';
import {ComponentStore} from '@ngrx/component-store';
import {Observable} from 'rxjs';
import {debounceTime, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {AttributeSearchModel, AzureAttributeModel} from '../models';
import {AttributeService} from '../services';
import {CriteriaBuilderStore} from './criteria-builder.store';
import {RootStore} from './root.store';

export interface AttributeState {
  attributes: AzureAttributeModel[];
}

export const initialState: AttributeState = {
  attributes: []
};

@Injectable()
export class AttributeStore extends ComponentStore<AttributeState> {

  constructor(private attributeService: AttributeService,
              private criteriaBuilderStore: CriteriaBuilderStore,
              private rootStore: RootStore) {
    super(initialState);
  }

  readonly search = this.effect((attributeSearchObj$: Observable<AttributeSearchModel>) => {
    return attributeSearchObj$.pipe(
      debounceTime(250),
      withLatestFrom(this.rootStore.getConfig),
      switchMap(([searchObj, config]) => this.attributeService.search(searchObj.searchTerm, config.userContext.language).pipe(
        withLatestFrom(
          this.criteriaBuilderStore.getAttributeIdsForAttributeCriteria(searchObj.criteriaId, searchObj.attributeCriteriaId),
          this.rootStore.getConfig
        ),
        map(([searchResponse, attributeIds]) => this.setAttributes(
          searchResponse.value
            .filter((attr: any) => !attributeIds.includes(attr.id))
            .map((attr) => new AzureAttributeModel(attr, config.userContext.language)))
          )
      ))
    );
  });

  setAttributes(attributes: AzureAttributeModel[]): void {
    this.setState((state) => ({
      attributes: [...attributes]
    }));
  }

  clearAttributes(): void {
    this.setState(initialState);
  }
}
