<div *ngFor="let criteriaId of criteriaList$ | async">
  <cdb-criteria-builder [criteria]="getCriteria$(criteriaId) | async"
                        (doSearch)="emitDoSearch()"
                        (removeCriteria)="removeCriteria($event)"
  ></cdb-criteria-builder>
</div>
<button (click)="addCriteria()"
        class="secondary"
>{{'Add Criteria' | translate }}</button>

<!--<button (click)="showCriteriaData = !showCriteriaData">show criteria data</button>-->

