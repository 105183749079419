import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BasketTypeEnum} from '../enums';
import {SaveService} from '../interfaces';
import {Config} from '../models';
import {ProcurementBasketSaveService} from '../services';
import {OrderGuideSaveService} from '../services/order-guide-save.service';
import {CriteriaBuilderStore} from '../store/criteria-builder.store';
import {RootStore} from '../store/root.store';

@Injectable()
export class SaveServiceFactory {
  config: Config;

  constructor(private readonly rootStore: RootStore,
              private readonly httpClient: HttpClient,
              private readonly criteriaBuilderStore: CriteriaBuilderStore) {
    this.rootStore.getConfig.subscribe((cfg: Config) => this.config = cfg);
  }

  getService(): SaveService {
    switch (this.config.entityContext.entityType) {
      case BasketTypeEnum.OrderGuide:
      case BasketTypeEnum.ShoppingList:
        return new OrderGuideSaveService(this.httpClient, this.criteriaBuilderStore, this.rootStore);
      case BasketTypeEnum.ProcurementBasket:
        return new ProcurementBasketSaveService(this.httpClient, this.criteriaBuilderStore, this.rootStore);
      default:
        throw new Error(`Unexpected entity type ${this.config.entityContext.entityType}. No service was found for this type.`);
    }
  }
}
