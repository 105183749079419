import {SavedSearchTypeEnum, SearchRankTypeEnum} from '../enums';
import {CriteriaDto} from './criteria.dto';

export class SearchCriteriaDto {
  constructor(priceRank: SearchRankTypeEnum) {
    this.priceRank = priceRank;
  }

  criteriaOr: CriteriaDto[] = [];
  clientId: number;
  priceRank: SearchRankTypeEnum;
  savedSearchType: SavedSearchTypeEnum;
  isFavourite: boolean;
  isValVol: boolean;
  isUsedInListOrGuide: boolean;
  isUsedInRecipe: boolean;
  measureId: number;
  skip: number;
  number: number;
}
