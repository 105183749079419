import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {filter, map, take} from 'rxjs/operators';
import {SavedSearchDto} from '../../dtos';
import {OrderGuideType, SavedSearchTypeEnum} from '../../enums';
import {AzureAttributeModel, Config, CriteriaModel, SearchHeaderModel, UomCriteriaModel} from '../../models';
import {AttributeModel} from '../../models/attribute.model';
import {AttributeService, CriteriaTransformerService, SavedSearchService} from '../../services';
import {AttributeStore} from '../../store/attribute.store';
import {CriteriaBuilderStore} from '../../store/criteria-builder.store';
import {MeasureStore} from '../../store/measure.store';
import {RootStore} from '../../store/root.store';
import {SearchHeaderStore} from '../../store/search-header.store';
import {SkuStore} from '../../store/sku.store';

@Component({
  selector: 'cdb-criteria-builder-container',
  templateUrl: './criteria-builder-container.component.html',
  styleUrls: ['./criteria-builder-container.component.css'],
  providers: [
    AttributeStore
  ]
})
export class CriteriaBuilderContainerComponent implements OnInit {
  criteriaList$ = this.criteriaBuilderStore.select((state) => Object.keys(state.criteriaList));
  showCriteriaData: boolean;
  count = 0;
  config: Config;
  @Output() doSearch = new EventEmitter<any>();

  constructor(private readonly attributeService: AttributeService,
              private readonly criteriaBuilderStore: CriteriaBuilderStore,
              private readonly criteriaTransformerService: CriteriaTransformerService,
              private readonly searchHeaderStore: SearchHeaderStore,
              private readonly rootStore: RootStore,
              private readonly savedSearchService: SavedSearchService,
              private readonly skuStore: SkuStore,
              private readonly measureStore: MeasureStore) {
  }

  ngOnInit(): void {
    this.rootStore.getConfig.subscribe((c) => {
      this.config = c;
      // We are not using the factored measures, but we need to make sure the store is populated before processing
      this.measureStore.selectFactoredMeasures.subscribe((fm) => {
        const fromSkuSearch = window.location.pathname.match(/create/);
        // If we get a match then we are coming from SKU search. Grab attribute Ids from storage
        if (fromSkuSearch) {
          const advancedSearchObj = this.attributeService.getAttributeSearchFromStorage();
          this.getAttributesByIds(advancedSearchObj.attributeIds)
            .subscribe((azureAttributes) => {
              this.addCriteria();
              azureAttributes.forEach((attr) => this.criteriaBuilderStore.updateAttributesOnCriteria(new AttributeModel(0, 0, attr)));
              advancedSearchObj.uom.criteriaId = 0;
              this.rootStore.setReturnUrl(advancedSearchObj.returnUrl);
              this.criteriaBuilderStore.updateUomCriteria(advancedSearchObj.uom);
              this.searchHeaderStore.setPriceRank(advancedSearchObj.priceRank);
              this.emitDoSearch();
            });
        } else if (c.entityContext.basketItemId) {
          // We have a savedSearchId so we need to retrieve it
          this.savedSearchService.get(c.entityContext.basketItemId)
            .pipe(take(1))
            .subscribe((savedSearch: SavedSearchDto) => {
              const attributeIds = [];
              savedSearch.searchDto.searchCriteria.criteriaOr.forEach((criteriaOr) =>
                criteriaOr.attributeAnd.forEach((attributeAnd) =>
                  Object.keys(attributeAnd.attributes).forEach((attributeId) => {
                    attributeIds.push(parseInt(attributeId, null));
                  })));

              this.getAttributesByIds(attributeIds)
                .subscribe((azureAttributes) => {
                  const transformedSearch = this.criteriaTransformerService.mapToModel(savedSearch.searchDto, azureAttributes);
                  this.criteriaBuilderStore.setStateFromModel(transformedSearch.criteria);
                  this.rootStore.setSavedSearch(savedSearch);
                  this.skuStore.updateSkuSaveOption(savedSearch.searchDto.searchCriteria.savedSearchType);
                  this.skuStore.setMeasureId(savedSearch.searchDto.searchCriteria.measureId);
                  this.skuStore.setShouldPassMeasureId(true);

                  this.searchHeaderStore.setSearchHeader({
                    isUsedInRecipe: savedSearch.searchDto.searchCriteria.isUsedInRecipe,
                    isValVol: savedSearch.searchDto.searchCriteria.isValVol,
                    isFavourite: savedSearch.searchDto.searchCriteria.isFavourite,
                    isUsedInListOrGuide: savedSearch.searchDto.searchCriteria.isUsedInListOrGuide,
                    name: savedSearch.searchDto.name,
                    priceRank: savedSearch.searchDto.searchCriteria.priceRank
                  } as SearchHeaderModel);
                  this.count = savedSearch.searchDto.searchCriteria.criteriaOr.length;
                  this.emitDoSearch();
                });
            });
        } else {
          this.addCriteria();
        }

        if (this.config.entityContext.additionalData) {
          if (this.config.entityContext.additionalData.sheetType === OrderGuideType.OrderGuide) {
            this.skuStore.updateSkuSaveOption(SavedSearchTypeEnum.advancedSearchExactSku);
          } else if (this.config.entityContext.additionalData.sheetType === OrderGuideType.OrderSheet) {
            this.skuStore.updateSkuSaveOption(SavedSearchTypeEnum.advancedSearchUseRecommended);
          }
        }

        // Handling this outside of the previous if block as the if block is an if/else if/else.
        if (!fromSkuSearch) {
          this.rootStore.setReturnUrl(c.endpoints.returnUrl);
        }
      });
    });
  }

  private getAttributesByIds(attributeIds: any[]): Observable<AzureAttributeModel[]> {
    return this.attributeService.getByIds(attributeIds)
      .pipe(filter((a) => !!a))
      .pipe(take(1))
      .pipe((response) => response
        .pipe(map((mappedResponse) =>
            mappedResponse.value.map(
              (value) => new AzureAttributeModel(value, this.config.userContext.language))
          )
        )
      );
  }

  addCriteria(): void {
    const newCriteria = new CriteriaModel();
    newCriteria.id = this.count;
    newCriteria.uomCriteria = new UomCriteriaModel(newCriteria.id);
    newCriteria.attributeCriteria[0].criteriaId = newCriteria.id;
    this.criteriaBuilderStore.addCriteria(newCriteria);
    this.count++;
  }

  getCriteria$(criteriaId: string): Observable<CriteriaModel> {
    return this.criteriaBuilderStore.select((state) => state.criteriaList[criteriaId]);
  }

  removeCriteria(id: number): void {
    this.criteriaBuilderStore.removeCriteria(id);
    this.emitDoSearch();
  }

  emitDoSearch(): void {
    this.doSearch.emit();
  }
}
