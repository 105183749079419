<div class="cdb-block-first">
  <div *ngFor="let attributeCriteriaKey of attributeCriteriaKeys | async">
    <cdb-attribute-criteria
      [attributeCriteria]="getAttributeCriteria$(attributeCriteriaKey) | async"
      [criteriaId]="criteria.id"
      [disableDelete]="disableDelete()"
      (addAttribute)="addAttribute($event)"
      (attributeIsIncludeChanged)="attributeIsIncludeChanged($event)"
      (attributeSearchTermChanged)="attributeSearchTermChanged($event)"
      (clearAttributeSearch)="clearAttributeSearch()"
      (removeAttribute)="removeAttribute($event)"
      (removeAttributeCriteria)="removeAttributeCriteria($event)"
      (operatorChanged)="operatorChanged($event)"
    ></cdb-attribute-criteria>
  </div>
  <button (click)="addAttributeCriteria()"
          class="secondary"
  >And
  </button>
</div>
