import {Injectable} from '@angular/core';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {ComponentStore} from '@ngrx/component-store';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {EMPTY, Observable, pipe} from 'rxjs';
import {debounceTime, skipWhile, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {AdvancedSearchDto, SaveSkuDto} from '../dtos';
import {BasketTypeEnum, SavedSearchTypeEnum} from '../enums';
import {UomTypeEnum} from '../enums/uomType.enum';
import {SaveServiceFactory} from '../factories';
import {CriteriaModel, SearchResponseModel, SkuModel, SortModel} from '../models';
import {CriteriaTransformerService, FactoredMeasuresService, SkuSearchService} from '../services';
import {CriteriaBuilderStore} from './criteria-builder.store';
import {MeasureStore} from './measure.store';
import {RootStore} from './root.store';
import {SearchHeaderStore} from './search-header.store';

export interface SkuSearchState {
  results: SkuModel[];
  saveOption: SavedSearchTypeEnum;
  skusLoading: boolean;
  skuSaving: boolean;
  skuSort: SortModel;
  measureId: number;
  shouldPassMeasureId: boolean;
}

export const InitialState: SkuSearchState = {
  results: [],
  saveOption: SavedSearchTypeEnum.advancedSearchExactSku,
  skusLoading: false,
  skuSaving: false,
  skuSort: null,
  measureId: null,
  shouldPassMeasureId: false
};

@Injectable()
export class SkuStore extends ComponentStore<SkuSearchState> {
  constructor(private readonly criteriaBuilderStore: CriteriaBuilderStore,
              private readonly rootStore: RootStore,
              private readonly factoredMeasuresService: FactoredMeasuresService,
              private readonly measuresStore: MeasureStore,
              private readonly searchHeaderStore: SearchHeaderStore,
              private readonly skuSearchService: SkuSearchService,
              private readonly saveServiceFactory: SaveServiceFactory,
              private readonly criteriaTransformerService: CriteriaTransformerService,
              private readonly toastr: ToastrService,
              private readonly translate: TranslateService) {
    super(InitialState);
  }

  readonly search = this.effect((criteria$: Observable<{ [id: number]: CriteriaModel }>) => {
    return criteria$.pipe(
      debounceTime(250),
      withLatestFrom(
        this.rootStore.select((state) => state.config),
        this.searchHeaderStore.select((state) => state.searchHeader),
        this.select((state) => state),
      ),
      switchMap(([criteriaObject, config, searchHeader, skuState]) => {
        // Our criteria is empty, don't waste time processing
        if (Object.keys(criteriaObject).length === 0) { return EMPTY; }

        const transformedDto = this.criteriaTransformerService.mapToDto(
          criteriaObject,
          searchHeader,
          config,
          skuState.shouldPassMeasureId ? skuState.measureId : null,
          skuState.skuSort
        );
        const measuresToCheck = this.createMeasureObjects(transformedDto);
        if (measuresToCheck.length && !this.factoredMeasuresService.areMeasuresFactorable(measuresToCheck)) {
          this.measuresStore.setAllMeasuresAreFactorable(false);
          return;
        }
        this.measuresStore.setAllMeasuresAreFactorable(true);
        this.setSkuResults([]);
        if (transformedDto.searchCriteria.criteriaOr.some((co) => !!co.attributeAnd.length)) {
          this.setSkusLoading(true);
          transformedDto.searchCriteria.clientId = config.userContext.clientId;
          return this.skuSearchService.search(transformedDto)
            .pipe(
              tap({
                next: (response: SearchResponseModel) => {
                  this.setSkuResults(response.skuDtos);
                  this.setMeasureId(response.measureId);
                  this.setShouldPassMeasureId(false);
                },
                complete: () => this.setSkusLoading(false)
              }));
        } else {
          this.setSkuResults([]);
          return EMPTY;
        }
      })
    );
  });

  readonly saveSku = this.effect((input$: Observable<SkuModel>) => {
    return input$.pipe(
      withLatestFrom(
        this.criteriaBuilderStore.select((state) => state.criteriaList),
        this.select((state) => state),
        this.rootStore.select((state) => state.config),
        this.searchHeaderStore.select((state) => state.searchHeader),
      ),
      switchMap(([sku, criteriaState, skuState, config, searchHeader]) => {
        this.setSkuSaving(true);
        const service = this.saveServiceFactory.getService();
        const advancedSearchDto = this.criteriaTransformerService.mapToDto(
          criteriaState,
          searchHeader,
          config,
          skuState.measureId,
          skuState.skuSort
        );
        advancedSearchDto.searchCriteria.savedSearchType = config.entityContext.entityType === BasketTypeEnum.ShoppingList ?
          SavedSearchTypeEnum.advancedSearchExactSku :
          skuState.saveOption;
        const saveSkuDto = new SaveSkuDto(sku, advancedSearchDto.searchCriteria, searchHeader);
        saveSkuDto.clientSupplierAccountDto = {id: sku.priceClientSupplierAccountID};
        return service.saveSku(sku.skuId, saveSkuDto)
          .pipe(
            tap({
              next: () => {
                // TODO This is not good. Please look at something better than window.location.href!!!!!!
                this.toastr.success(this.translate.instant(marker('SKU saved successfully')));
                window.location.href = config.endpoints.returnUrl;
              },
              complete: () => this.setSkuSaving(false)
            }));
      })
    );
  });

  updateSkuSort = this.updater((state, skuSort: SortModel) => ({
    ...state,
    skuSort
  }));

  updateSkuSaveOption = this.updater((state, savedSearchTypeEnum: SavedSearchTypeEnum) => ({
    ...state,
    saveOption: savedSearchTypeEnum
  }));

  setShouldPassMeasureId = this.updater((state, shouldPassMeasureId: boolean) => ({
    ...state,
    shouldPassMeasureId
  }));

  setSkusLoading = this.updater((state, loading: boolean) => ({
    ...state,
    skusLoading: loading
  }));

  setSkuSaving = this.updater((state, loading: boolean) => ({
    ...state,
    skuSaving: loading
  }));

  private createMeasureObjects(transformedDto: AdvancedSearchDto): { measureFrom: number, measureTo: number }[] {
    const measuresToCheck: { measureFrom: number, measureTo: number }[] = [];
    transformedDto.searchCriteria.criteriaOr.forEach((c) => c.uomAnd.forEach(u => {
      if (u.field !== UomTypeEnum.Unit) {
        return;
      }
      measuresToCheck.push(
        {
          measureFrom: u.leftMeasureId,
          measureTo: u.rightMeasureId
        });
    }));
    return measuresToCheck;
  }

  setSkuResults(skus: SkuModel[]): void {
    this.setState((state) => ({
      ...state,
      results: skus
    }));
    this.setSkusLoading(false);
  }

  resetState(): void {
    this.setState(InitialState);
  }

  setMeasureId(measureId: number): void {
    this.setState((state) => ({
      ...state,
      measureId
    }));
  }
}
