import {OperatorEnum} from '../enums';
import {AttributeCriteriaModel} from './attribute-criteria.model';
import {UomCriteriaModel} from './uom-criteria.model';

export class CriteriaModel {
  attributeCriteria: { [id: number]: AttributeCriteriaModel } = {};
  id: number;
  uomCriteria: UomCriteriaModel;
  measureId?: number;

  constructor() {
    this.attributeCriteria[0] = new AttributeCriteriaModel(this.id, OperatorEnum.All, 0);
  }
}
