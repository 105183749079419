import {SortDirection} from '@angular/material/sort';
import {SearchCriteriaDto} from './search-criteria.dto';

export class AdvancedSearchDto {
  searchCriteria: SearchCriteriaDto;
  getPricesAt: Date;
  languageCode: string;
  resultSize: number;
  columnRank?: string;
  columnSort?: SortDirection;
}
