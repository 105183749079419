import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OperatorEnum} from '../../enums';
import {AttributeCriteriaModel, AzureAttributeModel} from '../../models';
import {AttributeModel} from '../../models/attribute.model';
import {OperatorChanged} from '../../models/operator-changed.model';

@Component({
  selector: 'cdb-attribute-criteria',
  templateUrl: './attribute-criteria.component.html',
  styleUrls: ['./attribute-criteria.component.css']
})
export class AttributeCriteriaComponent implements OnInit {
  @Input() attributeCriteria: AttributeCriteriaModel;
  @Input() criteriaId: number;
  @Input() disableDelete: boolean;

  @Output() addAttribute = new EventEmitter<any>();
  @Output() attributeIsIncludeChanged = new EventEmitter<boolean>();
  @Output() attributeSearchTermChanged = new EventEmitter<any>();
  @Output() clearAttributeSearch = new EventEmitter();
  @Output() operatorChanged = new EventEmitter<OperatorChanged>();
  @Output() removeAttribute = new EventEmitter<AttributeModel>();
  @Output() removeAttributeCriteria = new EventEmitter<AttributeCriteriaModel>();

  attributeCriteriaOperator: OperatorEnum = OperatorEnum.Any;
  operatorEnum: typeof OperatorEnum = OperatorEnum;

  constructor() {
  }

  ngOnInit(): void {
  }

  emitAddAttribute(azureAttributeModel: AzureAttributeModel): void {
    this.addAttribute.emit({azureAttributeModel, attributeCriteriaId: this.attributeCriteria.id});
  }

  emitAttributeSearchTermChanged(searchTerm: string, attributeCriteriaId: number): void {
    this.attributeSearchTermChanged.emit({searchTerm, attributeCriteriaId, criteriaId: this.criteriaId});
  }

  emitRemoveAttribute(attribute: AttributeModel): void {
    this.removeAttribute.emit(attribute);
  }

  retainOrder(a, b): any {
    return 0;
  }

  emitClearAttributeSearch(): void {
    this.clearAttributeSearch.emit();
  }

  emitRadioChanged(operator: OperatorEnum, attributeCriteriaId: number): void {
    const operatorChanged = new OperatorChanged(operator, attributeCriteriaId);
    this.operatorChanged.emit(operatorChanged);
  }

  emitRemoveAttributeCriteria(attributeCriteria: AttributeCriteriaModel): void {
    this.removeAttributeCriteria.emit(attributeCriteria);
  }

  emitAttributeIsIncludeChanged($event: boolean): void {
    this.attributeIsIncludeChanged.emit($event);
  }
}
