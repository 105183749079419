import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {OperatorEnum} from '../../enums';
import {AttributeCriteriaModel, AttributeSearchModel, CriteriaModel} from '../../models';
import {AttributeModel} from '../../models/attribute.model';
import {OperatorChanged} from '../../models/operator-changed.model';
import {AttributeStore} from '../../store/attribute.store';
import {CriteriaBuilderStore} from '../../store/criteria-builder.store';

@Component({
  selector: 'cdb-attribute-criteria-container',
  templateUrl: './attribute-criteria-container.component.html',
  styleUrls: ['./attribute-criteria-container.component.css']
})
export class AttributeCriteriaContainerComponent implements OnInit {
  @Input() criteria: CriteriaModel;
  @Output() attributesModified = new EventEmitter<AttributeCriteriaModel>();
  @Output() doSearch = new EventEmitter<any>();

  attributeCriteriaKeys: Observable<string[]>;

  constructor(private readonly criteriaBuilderStore: CriteriaBuilderStore,
              private readonly attributeStore: AttributeStore) {
  }

  ngOnInit(): void {
      this.attributeCriteriaKeys = this.criteriaBuilderStore.getAttributeCriteriaKeys(this.criteria.id);
  }

  addAttribute({azureAttributeModel, attributeCriteriaId}): void {
    const newAttribute = new AttributeModel(this.criteria.id, attributeCriteriaId, azureAttributeModel);
    newAttribute.criteriaId = this.criteria.id;
    newAttribute.isInclude = true;
    this.criteriaBuilderStore.updateAttributesOnCriteria(newAttribute);
    this.doSearch.emit();
  }

  addAttributeCriteria(): void {
    const newAttributeCriteria = new AttributeCriteriaModel(this.criteria.id, OperatorEnum.All);
    const attributeCriteriaKeys = Object.keys(this.criteria.attributeCriteria);
    newAttributeCriteria.id = parseInt(attributeCriteriaKeys[attributeCriteriaKeys.length - 1], null) + 1;
    this.criteriaBuilderStore.addAttributeCriteria(newAttributeCriteria);
  }

  removeAttribute(a: AttributeModel): void {
    this.criteriaBuilderStore.removeAttribute(a);
    this.doSearch.emit();
  }

  attributeSearchTermChanged($event: AttributeSearchModel): void {
    this.attributeStore.search($event);
  }

  clearAttributeSearch(): void {
    this.attributeStore.clearAttributes();
  }

  operatorChanged($event: OperatorChanged): void {
    this.criteriaBuilderStore.updateOperator({
      operator: $event.operator,
      criteriaId: this.criteria.id,
      attributeCriteriaId: $event.attributeCriteriaId
    });
    this.doSearch.emit();
  }

  attributeIsIncludeChanged($event: any): void {
    this.doSearch.emit();
  }

  removeAttributeCriteria(attributeCriteria: AttributeCriteriaModel): void {
    this.criteriaBuilderStore.removeAttributeCriteria(attributeCriteria);
    this.doSearch.emit();
  }

  getAttributeCriteria$(attributeCriteriaKey: string): Observable<AttributeCriteriaModel> {
    return this.criteriaBuilderStore.select((state) => state.criteriaList[this.criteria.id].attributeCriteria[attributeCriteriaKey]);
  }

  disableDelete(): boolean {
    return Object.keys(this.criteria.attributeCriteria).length === 1;
  }
}
