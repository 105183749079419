import {SearchDto} from './';

export class SavedSearchDto {
  id: number;
  procurementBasketItemId: string;
  procurementSegmentItemId: number;
  searchDto: SearchDto;
  segmentId: number;
  skuId: number;
  sortOrder: number;
}
