import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CriteriaModel} from '../../models';

@Component({
  selector: 'cdb-criteria-builder',
  templateUrl: './criteria-builder.component.html',
  styleUrls: ['./criteria-builder.component.css']
})
export class CriteriaBuilderComponent implements OnInit {
  @Input() criteria: CriteriaModel;
  @Input() criteriaCount: number;
  @Output() doSearch = new EventEmitter<any>();
  @Output() removeCriteria = new EventEmitter<number>();

  constructor() {
  }

  ngOnInit(): void {
  }

  emitRemoveCriteria(id: number): void {
    this.removeCriteria.emit(id);
    this.emitDoSearch();
  }

  emitDoSearch(): void {
    this.doSearch.emit();
  }
}
